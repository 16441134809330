import React from "react";
import PropTypes from "prop-types";
// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles";
import Tooltip from '@material-ui/core/Tooltip';
import CircularProgress from '@material-ui/core/CircularProgress';

// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import {createMuiTheme, MuiThemeProvider} from '@material-ui/core/styles';
import CustomAutoComplete from "../../components/CustomInput/CustomAutocomplete";
import CustomDate from "../../components/CustomInput/CustomDate";
import ListItems from "../../components/ListItems/ListItems";

import customerJournalStyle from "assets/jss/material-dashboard-react/views/customerJournalStyle.jsx";
import Snackbar from "components/Snackbar/Snackbar.jsx";
import CustomTooltip from "components/Tooltip/CustomTooltip.jsx";
import MUIDataTable from "mui-datatables";
import {api_ipaddress} from '../../variables.js'
import axios from 'axios';
import AlertDialog from "components/Dialog/Dialog.jsx";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faClock} from '@fortawesome/free-regular-svg-icons'
import {faCheck, faCheckSquare, faCommentAlt, faParking, faSquare, faTimes, faTruck} from '@fortawesome/free-solid-svg-icons'
import Switch from '@material-ui/core/Switch';
import {initlocalStorage} from 'functions/sessionInit.js'
import {primaryColor} from "assets/jss/material-dashboard-react.jsx";
import {floatToText, textToFloat} from "functions/helpers.js";

import {withTranslation} from 'react-i18next';

import 'moment/locale/fr'
import 'moment/locale/it'
import 'moment/locale/nl'
import 'moment/locale/de-ch'
import Counters from "../../components/Counters/Counters";
import Button from "../../components/CustomButtons/Button";

var moment = require('moment-timezone');

function getFirstDay(year) {
  var d = new Date(year, 0, 1);
  var day = d.getDay();
  d.setDate(d.getDate() + (day%6? 0 :  (++day%4)));
  return moment(d);
}

const shortenLongString = (string) => {
  return string.length > 12 ? string.substring(0, 12) + "..." : string
}

const timeDelayInMinutes = (dtActual, dtTarget) => {
  return moment.duration(moment(dtActual).diff(moment(dtTarget))).asMinutes()
}

const textFromDelay = (DelayMinutes, t) => {
  return DelayMinutes >= 16 ?
    t("dashboard_delayed") :
    DelayMinutes >= 1 ?
      t("dashboard_marg") :
      t("dashboard_ontime")
}

const getDelayStatus = (r, event, t) => {
  let driving, time_departure, DelayMinutes
  if (event === "positioning") {
    if (r.tt_etp == null) return "-"
    DelayMinutes = timeDelayInMinutes(r.tt_etp, r.tt_stp)
    driving = r.tt_atp ? t("dashboard_arrived") : t("dashboard_in_transit")
  } else if (event === "departure") {
    if (r.tt_atp == null) return "-"
    time_departure = r.tt_atd ? moment(r.tt_atd) : moment()
    DelayMinutes = timeDelayInMinutes(time_departure, r.tt_std)
    driving = r.tt_atd ? t("dashboard_departed") : t("dashboard_waiting")
  } else if (event === "arrival") {
    if (r.tt_eta == null) return "-"
    DelayMinutes = timeDelayInMinutes(r.tt_eta, r.tt_sta)
    driving = r.tt_ata ? t("dashboard_arrived") : t("dashboard_in_transit")
  }
  return `${textFromDelay(DelayMinutes, t)} (${driving})`
}

const getDrivingStatus = (r, t) => {
  if (r.tt_status === "inactive")
    return t("dashboard_inactive")
  if (r.tt_status === "canceled")
    return t("dashboard_canceled")
  if (r.tt_ignition == null || r.tt_ata != null ||
    (r.tt_etp == null & r.tt_eta == null)) return "-"
  const status = r.tt_ignition === 1 && (r.tt_atd != null || r.tt_atp == null) ?
    t("dashboard_drives") : t("dashboard_stands")
  return status
}

const getDayOfTheWeek = (momentObj, t)  => {
  let dayOfWeek = momentObj.isoWeekday()
  let dayAbbr = ""
  switch (dayOfWeek) {
    case 1:
      dayAbbr = t("general_weekday_abbr_monday")
      break;
    case 2:
      dayAbbr = t("general_weekday_abbr_tuesday")
      break;
    case 3:
      dayAbbr = t("general_weekday_abbr_wednesday")
      break;
    case 4:
      dayAbbr = t("general_weekday_abbr_thursday")
      break;
    case 5:
      dayAbbr = t("general_weekday_abbr_friday")
      break;
    case 6:
      dayAbbr = t("general_weekday_abbr_saturday")
      break;
    case 7:
      dayAbbr = t("general_weekday_abbr_sunday")
      break;
    default:
      dayAbbr = momentObj.format("dd")
      break;
  }
  return dayAbbr;
}


const asTime = (momentObj, t) => {
  try {
    return momentObj.isValid() ? `${getDayOfTheWeek(momentObj, t)}  ${momentObj.format("HH:mm")}` : ""
  }
  catch (e) { return "" }
}

const asDate = (momentObj) => {
  try {
    return momentObj.isValid() ? momentObj.format("DD.MM.YYYY") : ""
  }
  catch (e) { return "" }
}

const dateFormat = "DD.MM.";
function pad(a, b) { return (1e15 + a + "").slice(-b) }
function getWeekToChoose(week) {
  let labelWeek = pad(week, 2)
  let currentYear =  moment().year()

  let momentWeek = moment().startOf('year').week(week)
  if (momentWeek.year() !== currentYear) {
    momentWeek = getFirstDay(currentYear).week(week)
  }

  let firstDay = moment(Object.assign({}, momentWeek.weekday(0)))
  let lastDay = moment(Object.assign({}, momentWeek.weekday(6)))

  return { value: week, label: `W${labelWeek} (${firstDay.format(dateFormat)} - ${lastDay.format(dateFormat)})` };
}

class SubcontractorJournal extends React.Component {

  constructor(props) {
    super(props);

    this.weeksAvailable = [...Array(moment().weeksInYear()).keys()].map((week) =>{return getWeekToChoose(week + 1)})
    const previousWeek = moment().week() - 1;
    let currentYear =  moment().year()
    let momentWeek = moment().week(previousWeek)
    if (momentWeek.year() !== currentYear) {
      momentWeek = getFirstDay(currentYear).week(previousWeek)
    }

    let dateFrom = moment(Object.assign({}, momentWeek.weekday(0)))
    let dateTo = moment(Object.assign({}, momentWeek.weekday(6)))

    this.state = {
      value: 0,
      tableLayout: "scroll",
      alertAskEdit: false,
      tc: false,
      message: "",
      updateDT: 1,
      subcontractorJournalFilterList: {},
      searchText: "",
      sortedCol: "stp",
      sortDirection: "asc",
      subcontractorJournalColListDesktop: {},
      subcontractorJournalColListMobile: {},
      data: "",
      subcontractors: [],
      isChecked: true,
      date_from: dateFrom,
      date_to: dateTo,
      week_from: null,
      week_to: null,
      invoiceList: [],
      references:[],
      calendarList: [],
      subcontractor:"",
      page: 0,
      rowsPerPage: 10,
    };

    this.removeInvoiceItem = this.removeInvoiceItem.bind(this)
    this.addInvoiceItem = this.addInvoiceItem.bind(this)
    this.changeInvoiceItem = this.changeInvoiceItem.bind(this)

    this.onChangeCustomer = this.onChangeCustomer.bind(this)
    this.onChangeCalendar = this.onChangeCalendar.bind(this)
    this.buildExcelRequest = this.buildExcelRequest.bind(this)
    this.changePage = this.changePage.bind(this)
  }

  showNotification(place, message) {
    this.setState({
      [place]: true,
      message: message
    });

  }

  componentWillMount() {
    initlocalStorage();
  }

  componentDidMount() {
    moment.tz.setDefault();
    const previousWeek = moment().week() - 1;
    let currentYear =  moment().year()
    let momentWeek = moment().week(previousWeek)
    if (momentWeek.year() !== currentYear) {
      momentWeek = getFirstDay(currentYear).week(previousWeek)
    }

    const dateFrom = this.updateCustomerJournalFiltersFromState('dateFrom');
    const dateTo = this.updateCustomerJournalFiltersFromState('dateTo');

    this.setState({
      subcontractorJournalFilterList: JSON.parse(localStorage.getItem("subcontractorJournalFilterList")),
      isChecked: this.updateCustomerJournalFiltersFromState('isChecked'),
      date_from: dateFrom ? moment(dateFrom) : moment(Object.assign({}, momentWeek.weekday(0))),
      date_to: dateTo ? moment(dateTo) : moment(Object.assign({}, momentWeek.weekday(6))),
      week_from: this.updateCustomerJournalFiltersFromState('weekFrom'),
      week_to: this.updateCustomerJournalFiltersFromState('weekTo'),
      invoiceList: this.updateCustomerJournalFiltersFromState('invoiceList'),
      calendarList: this.updateCustomerJournalFiltersFromState('calendarList'),
      subcontractorJournalColListDesktop: JSON.parse(localStorage.getItem("subcontractorJournalColListDesktop")),
      subcontractorJournalColListMobile: JSON.parse(localStorage.getItem("subcontractorJournalColListMobile")),
      page: this.updateCustomerJournalFiltersFromState('page'),
      rowsPerPage: this.updateCustomerJournalFiltersFromState('rowsPerPage'),
    })
    this.getsubcontractors();
  }

  updateData(startDay, endDay, subcontractor) {
    if (startDay && endDay && subcontractor) {
      if (endDay.diff(startDay, 'days') <= (startDay.isLeapYear() ? 366 : 365)) {
        this.setState({ data: ""})
        axios.get(`${api_ipaddress}/api/subcontractorjournal/timetable`,
          {
            headers: { "Authorization": `Bearer ${localStorage.getItem('access_token')}` },
            params: {
              role: localStorage.getItem('role'),
              username: localStorage.getItem('username'),
              startDay: moment(startDay).format("YYYY-MM-DD"),
              endDay: moment(endDay).format("YYYY-MM-DD"),
              subcontractor: subcontractor
            }
          })
          .then(res => {
            let total = 0
            res.data.forEach( (ele) => {

              if (ele.tt_invoice_check) {
                total += ele.tt_customer_invoice_trip
              }

              ele.tt_customer_invoice_trip = floatToText(ele.tt_customer_invoice_trip)
              ele.tt_customer_invoice_toll = floatToText(ele.tt_customer_invoice_toll)
              ele.tt_customer_invoice_waiting_fee = floatToText(ele.tt_customer_invoice_waiting_fee)
              ele.tt_customer_invoice_cancellation_fee = floatToText(ele.tt_customer_invoice_cancellation_fee)
              ele.tt_sub_invoice_trip = floatToText(ele.tt_sub_invoice_trip)
              ele.tt_sub_invoice_toll = floatToText(ele.tt_sub_invoice_toll)
              ele.tt_sub_invoice_waiting_fee = floatToText(ele.tt_sub_invoice_waiting_fee)
              ele.tt_sub_invoice_cancellation_fee = floatToText(ele.tt_sub_invoice_cancellation_fee)
            });
            let invoiceList = this.state.invoiceList
            invoiceList.forEach((i) => {
              const fuel = textToFloat(i['fuel'])
              i['extra_cost'] = floatToText((total/100) * fuel)
            })

            this.setState({
              data: res.data,
              invoiceList: invoiceList
            })
          })
          .catch(err => {
            this.setState({
              data: [],
            })
          })
      } else {
        this.setState({ data: []})
        alert(this.props.t("customer_journal_interval_message"))
      }
    } else {
      this.setState({ data: []})
    }
  }

  getsubcontractors(){
    axios.get(`${api_ipaddress}/api/subcontractors`,
      { headers: { "Authorization": `Bearer ${localStorage.getItem('access_token')}` } })
      .then(res => {

        const role = localStorage.getItem('role');
        const username = localStorage.getItem('username');

        //If subcontractor is logged in, it only can see himself
        if(role === "sub"){
          this.setState({ subcontractors: res.data
              .filter( name => {
                return name.sc_name === username
              })
              .map(subc => {
              return {"label": subc.sc_name, "value": subc}
            }), subcontractor: this.updateCustomerJournalFiltersFromState('subcontractor')
          });
        }else{
          this.setState({ subcontractors: res.data.map(subc => {
              return {"label": subc.sc_name, "value": subc}
            }), subcontractor: this.updateCustomerJournalFiltersFromState('subcontractor')
          });
        }



        const { isChecked, date_from, date_to, week_from, week_to } = this.state;
        const startDay = isChecked ? (week_from ? moment().startOf('year').week(week_from).weekday(0) : null): date_from
        const endDay = isChecked ? (week_to ? moment().startOf('year').week(week_to).weekday(6) : null) : date_to
        this.updateData(startDay, endDay, this.state.subcontractor);
      });
  }

  resize() {
    this.setState({
      tableLayout: window.innerWidth <= 620 ? "stackedFullWidth" : "scroll",
      windowHeight: window.innerHeight
    });
  }

  getMuiTheme = () => createMuiTheme({
    overrides: {
      MuiTableRow: {
        root: {
          height: "24px",
        }
      },
      MuiTableCell: {
        root: this.state.tableLayout === "scroll" ? {
          paddingRight: "4px",
          paddingTop: "6px",
          paddingLeft: "4px",
          paddingBottom: "6px",
          whiteSpace: "nowrap",
        } : {
          paddingTop: "4px",
          paddingBottom: "1px",
        }
      },
      MUIDataTableBodyCell: {
        stackedCommon: {
          width: "calc(50% - 32px) !important"
        }
      },
      MUIDataTable: {
        paperResponsiveScrollFullHeightFullWidth :{
          position: "relative",
        },
        responsiveScroll: {
          maxHeight: window.innerWidth <= 1280 ?
            `${this.state.windowHeight - 640}px !important` :
            `${this.state.windowHeight - 600}px !important`,
          minHeight: "300px",
        },
      },
    }
  })


  delayToIcon = (value, t) => {
    if (value == null) return null
    const iconColor =
      value.includes(t("dashboard_ontime")) ? "#23c644" :
        value.includes(t("dashboard_marg")) ? "#ffae00" :
          value.includes(t("dashboard_delayed")) ? "#ff4300" :
            "lightgrey"
    const icon =
      value.includes(t("dashboard_arrived")) || value.includes(t("dashboard_departed")) ?
        <FontAwesomeIcon icon={faCheckSquare} size="lg"
                         style={{ color: iconColor, paddingRight: "4px" }} /> :
        <FontAwesomeIcon icon={faSquare} size="lg"
                         style={{ color: iconColor, paddingRight: "4px" }} />
    return (iconColor !== "lightgrey" && this.state.tableLayout === "scroll") ?
      <Tooltip title={value}><div>{icon}</div></Tooltip> :
      icon;
  }

  drivingToIcon = (value, t) => {
    if (value === "-" || value === t("dashboard_inactive")) return " "
    const icon =
      value.includes(t("dashboard_drives")) ?
        <FontAwesomeIcon icon={faTruck} size="lg"
                         style={{ color: "gray", paddingRight: "4px" }} />
        :
        value.includes(t("dashboard_stands")) ?
          <FontAwesomeIcon icon={faParking} size="lg"
                           style={{ color: "lightgray", paddingRight: "4px" }} />
          : ""
    return this.state.tableLayout === "scroll" ?
      <Tooltip title={t("dashboard_car") + " " + value}><div>{icon}</div></Tooltip> :
      icon;
  }

  // column specifications
  timeOptions = (name, label, colList, t) => {
    return {
      filter: false, sort: true,
      sortDirection: this.state.sortedCol === name ? this.state.sortDirection : "none",
      customBodyRender: (momentObj) => asTime(momentObj, t),
      display: this.state[colList][name]
    }
  }

  dateOptions = (name, label, colList) => {
    return {
      filter: false, sort: true,
      sortDirection: this.state.sortedCol === name ? this.state.sortDirection : "none",
      customBodyRender: (momentObj) => asDate(momentObj),
      display: this.state[colList][name]
    }
  }

  standardOptions = (name, label, colList) => {
    return {
      filter: true, sort: true,
      sortDirection: this.state.sortedCol === name ? this.state.sortDirection : "none",
      display: this.state[colList][name]
    }
  }

  currencyOptions = (name, label, colList, t) => {
    return {
      filter: true, sort: true,
      sortDirection: this.state.sortedCol === name ? this.state.sortDirection : "none",
      customBodyRender: (value) => `${value} ${t('currency_symbol')}`,
      display: this.state[colList][name]
    }
  }

  delayOptions = (name, label, colList, dep = false, t) => {
    return {
      filter: true, sort: true,
      sortDirection: this.state.sortedCol === name ? this.state.sortDirection : "none",
      customBodyRender: (value) => this.delayToIcon(value, t),
      display: this.state[colList][name],
      filterOptions: dep ?
        [
          `${t("dashboard_ontime")} (${t("dashboard_departed")})`,
          `${t("dashboard_ontime")} (${t("dashboard_waiting")})`,
          `${t("dashboard_marg")} (${t("dashboard_departed")})`,
          `${t("dashboard_marg")} (${t("dashboard_waiting")})`,
          `${t("dashboard_delayed")} (${t("dashboard_departed")})`,
          `${t("dashboard_delayed")} (${t("dashboard_waiting")})`,
        ] :
        [
          `${t("dashboard_ontime")} (${t("dashboard_arrived")})`,
          `${t("dashboard_ontime")} (${t("dashboard_in_transit")})`,
          `${t("dashboard_marg")} (${t("dashboard_arrived")})`,
          `${t("dashboard_marg")} (${t("dashboard_in_transit")})`,
          `${t("dashboard_delayed")} (${t("dashboard_arrived")})`,
          `${t("dashboard_delayed")} (${t("dashboard_in_transit")})`,
        ]
    }
  }

  drivingOptions = (name, label, colList, t) => {
    return {
      filter: true, sort: true,
      sortDirection: this.state.sortedCol === name ? this.state.sortDirection : "none",
      customBodyRender: (v) => this.drivingToIcon(v, t),
      display: this.state[colList][name],
      filterOptions: [t("dashboard_drives"), t("dashboard_stands")]
    }
  }

  activeOptions = (name, colList) => {
    return {
      filter: false, searchable: false, sort: true,
      sortDirection: this.state.sortedCol === name ? this.state.sortDirection : "none",
      customBodyRender: (value) => {
        switch (value) {
          case "active":
            return <FontAwesomeIcon icon={faCheck} size="lg" style={{ color: "lightgray" }} />
          case "inactive":
            return <FontAwesomeIcon icon={faTimes} size="lg" style={{ color: "red" }} />
          case "scheduled":
            return <FontAwesomeIcon icon={faClock} size="lg" style={{ color: "lightgray" }} />
          case "canceled":
            return <strong style={{ color: "red" }}>CNL</strong>
          default:
            return "";
        }
      },
      display: this.state[colList]["active"],
    }
  }

  noteDesktopOptions = (name, colList, field) => {
    return {
      filter: false, searchable: false, sort: true,
      sortDirection: this.state.sortedCol === name ? this.state.sortDirection : "none",
      customBodyRender: (value) => {
        if (value !== "" && value !== null && value !== undefined) {
          let color = 'gray'
          if(field === 'info') {
            color = '#C20E1A'
          }

          return (
            <CustomTooltip title={value}><div>
              <FontAwesomeIcon
                icon={faCommentAlt}
                size="lg"
                style={{ color: color }}
              />
            </div></CustomTooltip>
          )
        }
        else
          return null;
      },
      display: this.state[colList][field],
    }
  }

  tableHeaderTooltip = (title, child) => {
    return{
      customBodyRender: (value) => {
        return(
          <CustomTooltip title={title}></CustomTooltip>
        )
      }

    }
  }

  noteMobileOptions = (name, colList) => {
    return {
      filter: false, searchable: false, sort: true,
      sortDirection: this.state.sortedCol === name ? this.state.sortDirection : "none",
      customBodyRender: (tt_note) => {
        if (tt_note !== "" && tt_note !== null) {
          return tt_note.length > 16 ? tt_note.substring(0, 15) + "..." : tt_note;
        }
        else
          return null;
      },
      display: this.state[colList]["note"]
    }
  }

  idOptions = (colList) => {
    return {
      display: this.state[colList]["id"],
      filter: false,
      sort: true,
      sortDirection: this.state.sortedCol === "id" ? this.state.sortDirection : "none",
      searchable: false
    }
  }

  overviewOptions = (colList) => {
    return {
      display: this.state[colList]["overview"],
      filter: false
    }
  }

  changePage = (page) => {
    const filters = this.changeCustomerJournalFilters(['page'], [page]);
    this.setState({ page: page, subcontractorJournalFilterList: filters })
  }

  addInvoiceItem = (event) => {
    const { invoiceList, isChecked, week_from, date_from } = this.state
    const startDay = isChecked ? (week_from ? moment().startOf('year').week(week_from).weekday(0) : null): date_from
    invoiceList.push( { "date": startDay, "fuel": '0.00', "fuel_error": false, "extra_cost":'0.00', "extra_cost_error": false, "invoice_text": "" })
    const filters = this.changeCustomerJournalFilters(['invoiceList'], [invoiceList]);
    this.setState({ invoiceList: invoiceList, subcontractorJournalFilterList: filters });
  };

  removeInvoiceItem = (key) => {
    const { invoiceList } = this.state
    invoiceList.splice(key, 1)
    const filters = this.changeCustomerJournalFilters(['invoiceList'], [invoiceList]);
    this.setState({ invoiceList: invoiceList, subcontractorJournalFilterList: filters });
  };

  changeInvoiceItem = (key, prop, value, error) => {
    const { invoiceList, data } = this.state

    let total = 0
    if (prop === 'fuel') {
      data.forEach( (ele) => {
        if (ele.tt_invoice_check) {
          const invoiceTripValue = textToFloat(ele.tt_sub_invoice_trip)
          total += invoiceTripValue
        }
      });
      invoiceList[key]['extra_cost'] = floatToText((total/100) * textToFloat(value))
    }

    if (prop === 'fuel' || prop === 'extra_cost')
      invoiceList[key][`${prop}_error`] = error

    invoiceList[key][prop] = value
    const filters = this.changeCustomerJournalFilters(['invoiceList'], [invoiceList]);
    this.setState({ invoiceList: invoiceList, subcontractorJournalFilterList: filters });
  }

  onChangeCalendar = (week, reference) => {
    const { calendarList } = this.state
    let item = calendarList.filter((itemWeek) => itemWeek.week === week)[0]
    item.reference = reference

    const filters = this.changeCustomerJournalFilters(['calendarList'], [calendarList]);
    this.setState({ calendarList: calendarList, subcontractorJournalFilterList: filters });
  }

  onChangeCustomer = (ref, value) => {
    const {subcontractor, subcontractors}  = this.state
    const customerInfo = subcontractors.filter((c)=> c.label === subcontractor)[0].value
    customerInfo[`cm_reference_${ref + 1}`] = value
    this.setState({subcontractors: subcontractors})
  }

  buildExcelRequest = () => {
    const {isChecked, date_from, date_to, week_from, week_to, invoiceList, subcontractor} = this.state

    const startDay = isChecked ? (week_from ? moment().startOf('year').week(week_from).weekday(0) : null): date_from
    const endDay = isChecked ? (week_to ? moment().startOf('year').week(week_to).weekday(6) : null) : date_to

    invoiceList.forEach((el) => {
      el.fuel = textToFloat(el.fuel)
      el.extra_cost = textToFloat(el.extra_cost)
    })

    return {
      "start_date": startDay,
      "end_date": endDay,
      "subcontractor": subcontractor,
      "invoice_items": invoiceList
    }
  }

  exportExcel = (request) => {
    if (request == null)
      return
    axios.post(`${api_ipaddress}/api/subcontractorjournal/subcontractorjournaldownload`, request,
      {
        headers: { "Authorization": `Bearer ${localStorage.getItem('access_token')}` },
        params: {
          role: localStorage.getItem('role'),
          username: localStorage.getItem('username')
        },
        responseType: 'blob'
      }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'subcontractor_journal.xlsx');
      document.body.appendChild(link);
      link.click();
      return false;
    })
      .catch((err) => {
        this.showNotification("tc", "Leider gab es ein Problem");
        return false;
      })
  }

  changeCustomerJournalFilters = (changedColumns, values) => {
    const subcontractorJournalFilterList = "subcontractorJournalFilterList";
    let tempColList = { ...this.state[subcontractorJournalFilterList] };

    changedColumns.forEach((el, idx) => {
      if (el === 'isChecked') {
        tempColList[el] = values[idx];
      } else {
        tempColList[el] = values[idx] ? values[idx] : "";
      }
    })

    localStorage.setItem(subcontractorJournalFilterList, JSON.stringify(tempColList));
    return tempColList
  }

  updateCustomerJournalFiltersFromState = (changedColumn) => {
    let tempFilterList = JSON.parse(localStorage.getItem("subcontractorJournalFilterList"));
    return tempFilterList[changedColumn];
  }

  setDefaultFilter = () => {
    const previousWeek = moment().week() - 1;
    let currentYear =  moment().year()
    let momentWeek = moment().week(previousWeek)
    if (momentWeek.year() !== currentYear) {
      momentWeek = getFirstDay(currentYear).week(previousWeek)
    }

    const defaultState = {
      "calendarList": [],
      "subcontractor": null,
      "dateFrom": moment(Object.assign({}, momentWeek.weekday(0))),
      "dateTo": moment(Object.assign({}, momentWeek.weekday(6))),
      "invoiceList": [],
      "isChecked": true,
      "weekFrom": null,
      "weekTo": null,
      "page": 0,
      "rowsPerPage": 10,
    }

    localStorage.setItem("subcontractorJournalFilterList",
      JSON.stringify(defaultState)
    )

    this.setState( {
      value: 0,
      alertAskEdit: false,
      tc: false,
      message: "",
      updateDT: 1,
      subcontractorJournalFilterList: defaultState,
      searchText: "",
      sortedCol: "stp",
      sortDirection: "asc",
      data: [],
      isChecked: true,
      date_from: moment(moment().week(previousWeek).weekday(0)),
      date_to: moment(moment().week(previousWeek).weekday(6)),
      week_from: null,
      week_to: null,
      invoiceList: [],
      references:[],
      calendarList: [],
      subcontractor:"",
      page:0,
      rowsPerPage: 10,
    } );

  }

  render() {
    const { tableLayout, subcontractor, subcontractors, date_from, date_to, week_from, week_to, isChecked, invoiceList, calendarList } = this.state;
    const { t, i18n } = this.props;
    const customerInfo = subcontractors.filter((c)=> c.label === subcontractor)[0]

    const data = this.state.data !== "" ? this.state.data.map(
        r => ({
          id: r.tt_id,
          invoice: r.tt_invoice_check,
          active: r.tt_status,
          tour: shortenLongString(r.tt_tour),
          sub_invoice_tour_combination: r.tt_tour_combination,
          from: shortenLongString(r.tt_from_text),
          to: shortenLongString(r.tt_to_text),
          gps: r.tt_gps,
          date: moment(r.tt_stp),
          status: getDrivingStatus(r, t),
          pos: getDelayStatus(r, "positioning", t),
          stp: moment(r.tt_stp),
          etp: moment(r.tt_etp),
          dep: getDelayStatus(r, "departure", t),
          std: moment(r.tt_std),
          atd: moment(r.tt_atd),
          arr: getDelayStatus(r, "arrival", t),
          sta: moment(r.tt_sta),
          eta: moment(r.tt_eta),
          driver: r.tt_driver,
          note: localStorage.getItem('role') === "admin" ? r.tt_note : "",
          info: (localStorage.getItem('role') === "admin" || localStorage.getItem('role') === "subcontractor" || localStorage.getItem('role') === "sub" || localStorage.getItem('role') === "observer") ? r.tt_info : "",
          overview: <div>
            {this.delayToIcon(getDelayStatus(r, "positioning", t), t)}
            {this.delayToIcon(getDelayStatus(r, "departure", t), t)}
            {this.delayToIcon(getDelayStatus(r, "arrival", t), t)}
            {this.drivingToIcon(getDrivingStatus(r, t), t)}
          </div>,
          customer_invoice_text: r.tt_customer_invoice_text,
          customer_invoice_trip: r.tt_customer_invoice_trip,
          customer_invoice_toll: r.tt_customer_invoice_toll,
          customer_invoice_waiting_fee: r.tt_customer_invoice_waiting_fee,
          customer_invoice_cancellation_fee: r.tt_customer_invoice_cancellation_fee,
          sub_invoice_trip: r.tt_sub_invoice_trip,
          sub_invoice_toll: r.tt_sub_invoice_toll,
          sub_invoice_waiting_fee: r.tt_sub_invoice_waiting_fee,
          sub_invoice_cancellation_fee: r.tt_sub_invoice_cancellation_fee,
          sub_invoice_comment: r.tt_sub_invoice_comment,
        })
      )
        .filter(r =>
          (!r.arr.includes(t("dashboard_arrived")) &&
            (r.sta.clone().add(6, 'hours') > moment()) && (
              r.pos.includes(t("dashboard_marg")) || r.pos.includes(t("dashboard_delayed")) ||
              r.dep.includes(t("dashboard_marg")) || r.dep.includes(t("dashboard_delayed")) ||
              r.arr.includes(t("dashboard_marg")) || r.arr.includes(t("dashboard_delayed")))) ||
          !JSON.parse(localStorage.getItem("panicbutton")))
      :
      null

    const columns = tableLayout === "scroll" ?
      [ // full desktop view
        { name: "id", label: "ID", options: this.idOptions("subcontractorJournalColListDesktop") },
        { name: "active", label: t("dashboard_active"), options: this.activeOptions("active","subcontractorJournalColListDesktop") },
        { name: "tour", label: t("dashboard_tour_nr"), options: this.standardOptions("tour", t("dashboard_tour_nr"), "subcontractorJournalColListDesktop") },
        { name: "sub_invoice_tour_combination", label: t("general_tour_combination"), options: this.standardOptions("sub_invoice_tour_combination", t("general_tour_combination"), "subcontractorJournalColListDesktop")},
        { name: "from", label: t("general_from"), options: this.standardOptions("from", t("general_from"), "subcontractorJournalColListDesktop") },
        { name: "to", label: t("general_to"), options: this.standardOptions("to", t("general_to"), "subcontractorJournalColListDesktop") },
        { name: "date", label: t("dashboard_date"), options: this.dateOptions("date", t("dashboard_date"), "subcontractorJournalColListDesktop", t) },
        { name: "status", label: t("dashboard_status"), options: this.drivingOptions("status", t("dashboard_status"), "subcontractorJournalColListDesktop", t) },
        { name: "pos", label: "P", options: this.delayOptions("pos", "P", "subcontractorJournalColListDesktop", false, t) },
        { name: "stp", label: "STP", options: this.timeOptions("stp", "STP", "subcontractorJournalColListDesktop", t) },
        { name: "etp", label: "ETP/ATP", options: this.timeOptions("etp", "ETP/ATP", "subcontractorJournalColListDesktop", t) },
        { name: "dep", label: "D", options: this.delayOptions("dep", "D", "subcontractorJournalColListDesktop", true, t) },
        { name: "std", label: "STD", options: this.timeOptions("std", "STD", "subcontractorJournalColListDesktop", t) },
        { name: "atd", label: "ATD", options: this.timeOptions("atd", "ATD", "subcontractorJournalColListDesktop", t) },
        { name: "arr", label: "A", options: this.delayOptions("arr", "A", "subcontractorJournalColListDesktop", false, t) },
        { name: "sta", label: "STA", options: this.timeOptions("sta", "STA", "subcontractorJournalColListDesktop", t) },
        { name: "eta", label: "ETA/ATA", options: this.timeOptions("eta", "ETA/ATA", "subcontractorJournalColListDesktop", t) },
        { name: "gps", label: t("dashboard_car"), options: this.standardOptions("gps", t("dashboard_car"), "subcontractorJournalColListDesktop") },
        { name: "driver", label: t("dashboard_driver"), options: this.standardOptions("driver", t("dashboard_driver"), "subcontractorJournalColListDesktop") },
        ...(localStorage.getItem('role') === "admin" ? [{ name: "customer_invoice_trip", label: t("dashboard_customer_invoice_trip"), options: this.currencyOptions("customer_invoice_trip", t("dashboard_customer_invoice_trip"), "subcontractorJournalColListDesktop", t)}] : []),
        ...(localStorage.getItem('role') === "admin" ? [{ name: "customer_invoice_toll", label: t("dashboard_customer_invoice_toll"), options: this.currencyOptions("customer_invoice_toll", t("dashboard_customer_invoice_toll"), "subcontractorJournalColListDesktop", t)}] : []),
        ...(localStorage.getItem('role') === "admin" ? [{ name: "customer_invoice_waiting_fee", label: t("dashboard_customer_invoice_waiting_fee"), options: this.currencyOptions("customer_invoice_waiting_fee", t("dashboard_customer_invoice_waiting_fee"), "subcontractorJournalColListDesktop", t)}] : []),
        ...(localStorage.getItem('role') === "admin" ? [{ name: "customer_invoice_cancellation_fee", label: t("dashboard_customer_invoice_cancellation_fee"), options: this.currencyOptions("customer_invoice_cancellation_fee", t("dashboard_customer_invoice_cancellation_fee"), "subcontractorJournalColListDesktop", t)}] : []),
        ...(localStorage.getItem('role') === "admin" || "sub" ? [{ name: "sub_invoice_trip", label: t("dashboard_sub_invoice_trip"), options: this.currencyOptions("sub_invoice_trip", t("dashboard_sub_invoice_trip"), "subcontractorJournalColListDesktop", t)}] : []),
        ...(localStorage.getItem('role') === "admin" || "sub" ? [{ name: "sub_invoice_toll", label: t("dashboard_sub_invoice_toll"), options: this.currencyOptions("sub_invoice_toll", t("dashboard_sub_invoice_toll"), "subcontractorJournalColListDesktop", t)}] : []),
        ...(localStorage.getItem('role') === "admin" || "sub" ? [{ name: "sub_invoice_waiting_fee", label: t("dashboard_sub_invoice_waiting_fee"), options: this.currencyOptions("sub_invoice_waiting_fee", t("dashboard_sub_invoice_waiting_fee"), "subcontractorJournalColListDesktop", t)}] : []),
        ...(localStorage.getItem('role') === "admin" || "sub" ? [{ name: "sub_invoice_cancellation_fee", label: t("dashboard_sub_invoice_cancellation_fee"), options: this.currencyOptions("sub_invoice_cancellation_fee", t("dashboard_sub_invoice_cancellation_fee"), "subcontractorJournalColListDesktop", t)}] : []),
        ...(localStorage.getItem('role') === "admin" || "sub" ? [{ name: "sub_invoice_comment", label: t("dashboard_sub_invoice_comment"), options: this.noteDesktopOptions("sub_invoice_comment", "subcontractorJournalColListDesktop", "sub_invoice_comment")}] : []),
        ...(localStorage.getItem('role') === "admin" ? [{ name: "customer_invoice_text", label: t("dashboard_customer_invoice_text"), options: this.noteDesktopOptions("customer_invoice_text", "subcontractorJournalColListDesktop", "customer_invoice_text")}] : []),
        { name: "info", label: t("dashboard_info"), options: this.noteDesktopOptions("info","subcontractorJournalColListDesktop", "info") },
        ...(localStorage.getItem('role') === "admin" ? [{ name: "note", label: t("dashboard_notes"), options: this.noteDesktopOptions("note","subcontractorJournalColListDesktop", "note") }] : [])
      ] :
      [ // reduced mobile view
        { name: "id", label: "ID", options: this.idOptions("subcontractorJournalColListMobile") },
        { name: "active", label: t("dashboard_active"), options: this.activeOptions("active","subcontractorJournalColListMobile") },
        { name: "tour", label: t("dashboard_tour_nr"), options: this.standardOptions("tour", t("dashboard_tour_nr"), "subcontractorJournalColListMobile") },
        { name: "from", label: t("general_from"), options: this.standardOptions("from", t("general_from"), "subcontractorJournalColListMobile") },
        { name: "to", label: t("general_to"), options: this.standardOptions("to", t("general_to"), "subcontractorJournalColListMobile") },
        { name: "overview", label: "P/D/A", options: this.overviewOptions("subcontractorJournalColListMobile") },
        { name: "stp", label: "STP", options: this.timeOptions("stp", "STP", "subcontractorJournalColListMobile", t) },
        { name: "etp", label: "ETP/ATP", options: this.timeOptions("etp", "ETP/ATP", "subcontractorJournalColListMobile", t) },
        { name: "std", label: "STD", options: this.timeOptions("std", "STD", "subcontractorJournalColListMobile", t) },
        { name: "atd", label: "ATD", options: this.timeOptions("atd", "ATD", "subcontractorJournalColListMobile", t) },
        { name: "sta", label: "STA", options: this.timeOptions("sta", "STA", "subcontractorJournalColListMobile", t) },
        { name: "eta", label: "ETA/ATA", options: this.timeOptions("eta", "ETA/ATA", "subcontractorJournalColListMobile", t) },
        { name: "gps", label: t("dashboard_car"), options: this.standardOptions("gps", t("dashboard_car"), "subcontractorJournalColListMobile") },
        { name: "driver", label: t("dashboard_driver"), options: this.standardOptions("driver", t("dashboard_driver"), "subcontractorJournalColListMobile") },
        ...(localStorage.getItem('role') === "admin" ? [{ name: "customer_invoice_trip", label: t("dashboard_customer_invoice_trip"), options: this.currencyOptions("customer_invoice_trip", t("dashboard_customer_invoice_trip"), "subcontractorJournalColListMobile", t)}] : []),
        ...(localStorage.getItem('role') === "admin" ? [{ name: "customer_invoice_toll", label: t("dashboard_customer_invoice_toll"), options: this.currencyOptions("customer_invoice_toll", t("dashboard_customer_invoice_toll"), "subcontractorJournalColListMobile", t)}] : []),
        ...(localStorage.getItem('role') === "admin" ? [{ name: "customer_invoice_waiting_fee", label: t("dashboard_customer_invoice_waiting_fee"), options: this.currencyOptions("customer_invoice_waiting_fee", t("dashboard_customer_invoice_waiting_fee"), "subcontractorJournalColListMobile", t)}] : []),
        ...(localStorage.getItem('role') === "admin" ? [{ name: "customer_invoice_cancellation_fee", label: t("dashboard_customer_invoice_cancellation_fee"), options: this.currencyOptions("customer_invoice_cancellation_fee", t("dashboard_customer_invoice_cancellation_fee"), "subcontractorJournalColListMobile", t)}] : []),
        ...(localStorage.getItem('role') === "admin" || "sub" ? [{ name: "sub_invoice_trip", label: t("dashboard_sub_invoice_trip"), options: this.currencyOptions("sub_invoice_trip", t("dashboard_sub_invoice_trip"), "subcontractorJournalColListMobile", t)}] : []),
        ...(localStorage.getItem('role') === "admin" || "sub" ? [{ name: "sub_invoice_toll", label: t("dashboard_sub_invoice_toll"), options: this.currencyOptions("sub_invoice_toll", t("dashboard_sub_invoice_toll"), "subcontractorJournalColListMobile", t)}] : []),
        ...(localStorage.getItem('role') === "admin" || "sub" ? [{ name: "sub_invoice_waiting_fee", label: t("dashboard_sub_invoice_waiting_fee"), options: this.currencyOptions("sub_invoice_waiting_fee", t("dashboard_sub_invoice_waiting_fee"), "subcontractorJournalColListMobile", t)}] : []),
        ...(localStorage.getItem('role') === "admin" || "sub" ? [{ name: "sub_invoice_cancellation_fee", label: t("dashboard_sub_invoice_cancellation_fee"), options: this.currencyOptions("sub_invoice_cancellation_fee", t("dashboard_sub_invoice_cancellation_fee"), "subcontractorJournalColListMobile", t)}] : []),
        ...(localStorage.getItem('role') === "admin" || "sub" ? [{ name: "sub_invoice_comment", label: t("dashboard_sub_invoice_comment"), options: this.noteDesktopOptions("sub_invoice_comment", "subcontractorJournalColListMobile", "sub_invoice_comment")}] : []),
        ...(localStorage.getItem('role') === "admin" ? [{ name: "customer_invoice_text", label: t("dashboard_customer_invoice_text"), options: this.noteDesktopOptions("customer_invoice_text", "subcontractorJournalColListMobile", "customer_invoice_text")}] : []),
        ...(localStorage.getItem('role') === "admin" ? [{ name: "note", label: t("dashboard_notes"), options: this.noteDesktopOptions("note","subcontractorJournalColListMobile", "note") }] : []),
        { name: "info", label: t("dashboard_info"), options: this.noteDesktopOptions("info","subcontractorJournalColListMobile", "info") }
      ];

    const options = {
      filterType: 'multiselect',
      search: false,
      filter: false,
      rowsPerPage: this.state.rowsPerPage,
      onRowClick: (rowData, rowMeta) => {
        let role = localStorage.getItem('role');
        if(role === "admin"){
          this.setState({
            alertAskEdit: true,
            clickedTrip: rowData[columns.findIndex(el => el.name === "id")],
            clickedTripNr: rowData[columns.findIndex(el => el.name === "tour")],
          })
        }else if(role === "sub"){
          if (["active", "scheduled"].includes(data[rowMeta.dataIndex].active)) {
            this.setState({
              alertAskEdit: true,
              clickedTrip: rowData[columns.findIndex(el => el.name === "id")],
              clickedTripNr: rowData[columns.findIndex(el => el.name === "tour")],
            })
          }else{
            console.log("not allowed")
          }
        }else{
          console.log("not allowed")
        }
      },
      onColumnViewChange: (changedColumn, action) => {
        const thelist = tableLayout === "scroll" ? "subcontractorJournalColListDesktop" : "subcontractorJournalColListMobile"
        let tempColList = { ...this.state[thelist] }
        tempColList[changedColumn] = action === "add" ? true : false
        this.setState({ [thelist]: tempColList })
        localStorage.setItem(thelist, JSON.stringify(tempColList))
      },
      onColumnSortChange: (changedColumn, direction) => {
        this.setState({
          sortedCol: changedColumn,
          sortDirection: direction === "descending" ? "desc" : "asc",
        })
      },
      onDownload: (buildHead, buildBody, columns, data) => {
        const {isChecked, date_from, date_to, week_from, week_to, subcontractor} = this.state
        const startDay = isChecked ? week_from : date_from
        const endDay = isChecked ? week_to: date_to
        if (startDay && endDay && subcontractor) {
          let request = this.buildExcelRequest();
          this.exportExcel(request);
        } else {
          alert('Invalid filters')
        }
        return false;
      },
      onChangeRowsPerPage: (numberOfRows) => {
        const filters = this.changeCustomerJournalFilters( ['rowsPerPage', 'page'], [numberOfRows, 0])
        this.setState({page: 0, rowsPerPage: numberOfRows, subcontractorJournalFilterList: filters})
      },
      pagination: true,
      rowsPerPageOptions: [10,50,100],
      elevation: 0,
      print: false,
      download: true,
      selectableRows: false,
      responsive: tableLayout,
      textLabels: {
        body: {
          noMatch: t("dashboard_no_trips_match_criteria"),
          toolTip: t("general_sort"),
        },
        toolbar: {
          search: t("general_search"),
          downloadCsv: "Download",
        },
        viewColumns: {
          title: t("dashboard_select_cols"),
          titleAria: t("dashboard_show_hide_cols"),
        },
      },
      onChangePage: this.changePage,
      page: this.state.page
    };

    const handleSwitchChange = (event) => {
      const startDay = event.target.checked ? (week_from ? moment().startOf('year').week(week_from).weekday(0) : null): date_from
      const endDay = event.target.checked ? (week_to ? moment().startOf('year').week(week_to).weekday(6) : null) : date_to

      let calendarList = []
      if (startDay && endDay) {
        const dayOfWeekFrom = startDay.week()
        const dayOfWeekTo = endDay.week()
        const weeks = this.weeksAvailable.filter((week) => week.value >= dayOfWeekFrom && week.value <= dayOfWeekTo)
        calendarList = weeks.map((week) => {return {"week": week.label, "reference": "" }})
      }

      const filters = this.changeCustomerJournalFilters(['isChecked', 'calendarList'], [event.target.checked, calendarList]);
      this.setState({ isChecked: event.target.checked, calendarList: calendarList, subcontractorJournalFilterList: filters });
      this.updateData(startDay, endDay, subcontractor)
    };

    let role = localStorage.getItem('role');

    const renderListItems = () => {
      if(role === "admin"){
        return  <div style={{'marginTop': '50px'}}>
          <GridContainer>
            <GridItem xs={6} sm={6} md={6}>
              <ListItems list={invoiceList}
                         startDate={ isChecked ? (week_from ? moment().startOf('year').week(week_from).weekday(0) : null): date_from }
                         endDate= { isChecked ? (week_to ? moment().startOf('year').week(week_to).weekday(6) : null) : date_to}
                         onChangeItem={this.changeInvoiceItem}
                         addItem={this.addInvoiceItem}
                         removeItem={this.removeInvoiceItem} t={t} />
            </GridItem>
          </GridContainer>
        </div>
      }
    }
    
    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card  style={{height: '92vh'}}>
              <CardHeader color="primary"
                          style={tableLayout === "scroll" ?
                            { display: "flex", justifyContent: "space-between" } :
                            {}}
              >
                <h4 style={tableLayout === "scroll" ?
                  { marginTop: "0px", marginBottom: "0px" } :
                  { marginTop: "0px", marginBottom: "10px" }}>
                  {t('subcontractor_journal')}
                </h4>
                <div style={{ marginTop: "-5px", marginBottom: "-10px"}}>
                  <span>Date</span>
                  <Switch
                    checked={isChecked}
                    onChange={handleSwitchChange}
                    disabled={localStorage.getItem('dashboard_view') === "last_week"}
                    value="week_date_selection"
                    style={{ color: "white" }}
                  />
                  <span>Week</span>
                </div>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={2} sm={2} md={3} >
                    <CustomAutoComplete
                      labelText={"subcontractorjournal_subcontractor"}
                      id="subcontractor"
                      value={subcontractors.filter(el => el.label === subcontractor)}
                      onChange={value => {
                        const filters = this.changeCustomerJournalFilters(['subcontractor'], [value ? value.label : ""]);
                        this.setState({ subcontractor: value !== null ? value.label : null, data: [], subcontractorJournalFilterList: filters });
                        if (value){
                          const startDay = isChecked ? (week_from ? moment().startOf('year').week(week_from).weekday(0) : null): date_from
                          const endDay = isChecked ? (week_to ? moment().startOf('year').week(week_to).weekday(6) : null) : date_to
                          this.updateData(startDay, endDay, value.label);
                        }
                      }}
                      required={true}
                      selectChoices={subcontractors}
                    />
                  </GridItem>
                  <GridItem xs={6} sm={6} md={2}>
                    <CustomDate
                      inputProps={{
                        label: t("general_date_from"),
                        id: "date_from",
                        value: date_from,
                        style: { marginTop:"7px" },
                        onChange: (date) => {
                          if (date) {
                            let calendarList = []
                            if (date_to) {
                              const dayOfWeekFrom = date.week()
                              const dayOfWeekTo = date_to.week()
                              const weeks = this.weeksAvailable.filter((week) => week.value >= dayOfWeekFrom && week.value <= dayOfWeekTo)
                              calendarList = weeks.map((week) => {return {"week": week.label, "reference": "" }})
                            }

                            const filters = this.changeCustomerJournalFilters(['calendarList', 'dateFrom'], [calendarList, moment(date)]);
                            this.setState({ date_from: date, calendarList: calendarList, subcontractorJournalFilterList: filters });
                            this.updateData(date, date_to, subcontractor);
                          }
                        },
                        onClear: () => this.setState({ date_from: null }),
                        disabled: isChecked
                      }}
                      formControlProps={{
                        fullWidth: true
                      }}
                      labelText={t("customer_journal_customer")}
                    />
                  </GridItem>
                  <GridItem xs={6} sm={6} md={2}>
                    <CustomDate
                      inputProps={{
                        label: t("general_date_to"),
                        id: "date_to",
                        value: date_to,
                        style: {marginTop:"7px"},
                        onChange: (date) => {
                          if (date) {
                            let calendarList = []
                            if (date_from) {
                              const dayOfWeekFrom = date_from.week()
                              const dayOfWeekTo = date.week()
                              const weeks = this.weeksAvailable.filter((week) => week.value >= dayOfWeekFrom && week.value <= dayOfWeekTo)
                              calendarList = weeks.map((week) => {return {"week": week.label, "reference": "" }})
                            }
                            const filters = this.changeCustomerJournalFilters(['calendarList', 'dateTo'], [calendarList, moment(date)]);
                            this.setState({ date_to: date, calendarList: calendarList, subcontractorJournalFilterList: filters });
                            this.updateData(date_from, date, subcontractor);
                          }
                        },
                        onClear: () => this.setState({ date_to: null }),
                        disabled: isChecked
                      }}
                      formControlProps={{
                        fullWidth: true
                      }}
                    />
                  </GridItem>
                  <GridItem xs={2} sm={2} md={2} >
                    <CustomAutoComplete
                      labelText={"general_week_from"}
                      id="weekSelectionFrom"
                      value={this.weeksAvailable.filter(el => el.value === week_from)}
                      onChange={value => {
                        let calendarList = []
                        if (week_to && value !== null) {
                          const weeks = this.weeksAvailable.filter((week) => week.value >= value.value && week.value <= week_to);
                          calendarList = weeks.map((week) => {return {"week": week.label, "reference": "" }});
                        }
                        if(value) {
                          const filters = this.changeCustomerJournalFilters(['calendarList', 'weekFrom'], [calendarList, value.value]);
                          this.setState({ week_from: value.value, calendarList: calendarList, subcontractorJournalFilterList: filters });
                        }else{
                          const filters = this.changeCustomerJournalFilters(['calendarList', 'weekFrom'], [calendarList, null]);
                          this.setState({week_from: null, calendarList: calendarList});
                        }
                        if(value){
                          this.updateData(moment().startOf('year').week(value.value).weekday(0), week_to ? moment().startOf('year').week(week_to).weekday(6) : null, subcontractor);
                        }

                      }}
                      selectChoices={this.weeksAvailable}
                      disabled={!isChecked}
                    />
                  </GridItem>
                  <GridItem xs={2} sm={2} md={2} >
                    <CustomAutoComplete
                      labelText={"general_week_to"}
                      id="weekSelectionTo"
                      value={this.weeksAvailable.filter(el => el.value === week_to)}
                      onChange={value => {
                        let calendarList = []
                        if (week_from && value !== null) {
                          const weeks = this.weeksAvailable.filter((week) => week.value >= week_from && week.value <= value.value)
                          calendarList = weeks.map((week) => {return {"week": week.label, "reference": "" }})
                        }
                        if(value){
                          const filters = this.changeCustomerJournalFilters(['calendarList', 'weekTo'], [calendarList, value.value]);
                          this.setState({ week_to: value.value, calendarList: calendarList, subcontractorJournalFilterList: filters });
                        }else{
                          const filters = this.changeCustomerJournalFilters(['calendarList', 'weekTo'], [calendarList, null]);
                          this.setState({ week_to: null, calendarList: calendarList, subcontractorJournalFilterList: filters });
                        }
                        if(value){
                          this.updateData( week_from ? moment().startOf('year').week(week_from).weekday(0) : null, moment().startOf('year').week(value.value).weekday(6), subcontractor);
                        }

                      }}
                      selectChoices={this.weeksAvailable}
                      disabled={!isChecked}
                    />
                  </GridItem>
                  <GridItem xs={1} sm={1} md={1}>
                    <Button style={{ float: "right", marginTop: "2.5rem" }} color="primary"
                            onClick={() => {
                              this.setDefaultFilter();
                            }}
                    >{t("customer_reset")}</Button>
                  </GridItem>
                </GridContainer>
                {this.state.data !== "" && <>
                  <MuiThemeProvider theme={this.getMuiTheme()}>
                    <MUIDataTable
                      key={
                        this.state.tableLayout +
                        this.state.updateDT +
                        i18n.language
                      }
                      // In order to have components on the left side, we have to pass the as title.
                      data={data}
                      columns={columns}
                      options={options}
                    />
                  </MuiThemeProvider>
                  <Counters labels={{Title:'Totals:', Total:"Total", Trip:"Subcontractor Trip", Toll:"Subcontractor Toll", CNL:"Subcontractor CNL", Waiting:"Subcontractor Waiting"}} data={this.state.data} invoiceList={invoiceList} sumIsChecked={false} properties={
                    {
                      trip: "tt_sub_invoice_trip",
                      toll: "tt_sub_invoice_toll",
                      cancellation_fee: "tt_sub_invoice_cancellation_fee",
                      waiting_fee: "tt_sub_invoice_waiting_fee",
                    }
                  }/>
                  {
                    //Only display "New Invoice" Item for admin
                    renderListItems()
                  }
                </>}
                {this.state.data === "" &&
                  <CircularProgress
                    disableShrink
                    style={{
                      color: primaryColor[0],
                      margin: "auto",
                      display: "block"
                    }} />}
              </CardBody>
            </Card>
            <AlertDialog
              open={this.state.alertAskEdit}
              onClose={() => this.setState({ alertAskEdit: false })}
              handleNo={() => this.setState({ alertAskEdit: false })}
              handleYes={() => this.props.history.push(`/admin/edit/${this.state.clickedTrip}`)}
              title={`${t("edit_edit_trip")}: ${this.state.clickedTripNr}?`}
            />
            <Snackbar
              place="tc" //topcenter
              color="primary"
              message={this.state.message}
              open={this.state.tc}
              closeNotification={() => this.setState({ tc: false })}
              close
            />
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

SubcontractorJournal.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withTranslation()(withStyles(customerJournalStyle)(SubcontractorJournal));
